@font-face {
  font-family: "NotoSansMono-Light";
  src: local("NotoSansMono"),
    url("./fonts/NotoSansMono/NotoSansMono-Light.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "NotoSansMono-Regular";
  src: local("NotoSansMono"),
    url("./fonts/NotoSansMono/NotoSansMono-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "NotoSansMono-Bold";
  src: local("NotoSansMono"),
    url("./fonts/NotoSansMono/NotoSansMono-Bold.ttf") format("truetype");
  font-weight: bold;
}

* {
  font-family: "NotoSansMono-Regular" !important;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-container {
  color: #fff;
  background: #171717;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.page-content {
  width: auto;
  background-color: #171717;
  min-height: 92%;
  color: #fff;
}

/* d0d0d0 Alternatif theme color */

/* Scroll Bar */

::-webkit-scrollbar {
  width: 0.7em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.25);
  outline: 2px solid #1976d2;
}

/* Swiper */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
